import React from 'react';
import classnames from 'classnames';
import Link from 'components/base/Link';

const BlockLinksList = ({ isNavList, className = '', taxa = [] }) => {
  return (
    <nav className={`block ${className}`}>
      {isNavList ? (
        <ul className="flex flex-wrap">
          <li className="db w-100 w-25-md ttu tc pv2 body-subheader">
            <Link to="/collection" className="gradient-hover">
              Catalog
            </Link>
          </li>
          <li className="db w-100 w-25-md ttu tc pv2 body-subheader">
            <Link to="/projects" className="gradient-hover">
              Projects
            </Link>
          </li>
          <li className="db w-100 w-25-md ttu tc pv2 body-subheader">
            <Link to="/community" className="gradient-hover">
              Community
            </Link>
          </li>
          <li className="db w-100 w-25-md ttu tc pv2 body-subheader">
            <Link to="/about" className="gradient-hover">
              About/Visit
            </Link>
          </li>
        </ul>
      ) : (
        <ul className="flex flex-wrap">
          {taxa.map((taxon, index) => {
            return (
              <li
                key={index}
                className={classnames(
                  'db w-100 w-25-md ttu tc pv2 body-subheader',
                  {
                    'brn br-md bb bbn-md': index < 3
                  }
                )}
              >
                <Link
                  to={`/collection/${taxon.slug}`}
                  className="gradient-hover"
                >
                  {taxon.name}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </nav>
  );
};

export default BlockLinksList;
