import React from 'react';
import Layout from 'components/Layout';
import Link from 'components/base/Link';
import CollectionCatalog from 'components/modules/CollectionCatalog';
import SEO from 'components/site/SEO';
import IconSearch from 'svg/icn-search';

const Taxon = ({ pageContext }) => {
  let { itemImage, slug, childTaxa, items, name } = pageContext;
  name = name.split('__')[name.split('__').length - 1];
  const itemsWithImages = items
    .filter(item => item.displayImage)
    .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
  const itemsWithoutImages = items
    .filter(item => !item.displayImage)
    .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
  items = itemsWithImages.concat(itemsWithoutImages);
  return (
    <Layout pageTitle="Catalog" link="/collection">
      <SEO
        title={name}
        imageURL={`${itemImage}?w=1200&h=600&fm=jpg&q=70`}
        description={`The ${name} category of the collection at The Media Archaeology Lab`}
      />
      <article className="CollectionTabs container container--thin container--boxed body-copy-only">
        <ul className="block flex">
          <Link
            className="CollectionTabs__search w-100 w-50-md br pointer tc pv2 relative body-subheader"
            to="/collection/search"
            fromTaxon={`collection/${slug}`}
            shouldAnimate={false}
          >
            <IconSearch className="CollectionTabs__search-title" />
          </Link>
          <Link
            className="CollectionTabs__index w-100 w-50-md pointer tc pv2 relative body-subheader"
            to="/collection/indexed"
            fromTaxon={`collection/${slug}`}
            shouldAnimate={false}
          >
            <span className="CollectionTabs__index-title">Index</span>
          </Link>
        </ul>
      </article>
      {/* The catalog grid view */}
      <CollectionCatalog
        className="CollectionTaxon"
        name={name}
        prevUrl={slug
          .split('/')
          .slice(0, slug.split('/').length - 1)
          .join('/')}
        taxa={childTaxa
          .map(item => {
            item.name = item.name.split('__')[item.name.split('__').length - 1];
            return item;
          })
          .sort((a, b) => b.itemsLen - a.itemsLen)}
        items={items}
      />
    </Layout>
  );
};

export default Taxon;
