import React from 'react';

const IconSearch = ({ className = '' }) => {
  return (
    <svg className={className} width="21px" height="20px" viewBox="0 0 21 20">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-190.000000, -89.000000)"
          stroke="#000000"
          strokeWidth="1"
        >
          <g transform="translate(191.000000, 90.000000)">
            <circle cx="7.6" cy="7.6" r="7.6"></circle>
            <line x1="19.2" y1="18.9821013" x2="13.0178987" y2="12.8"></line>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconSearch;
