import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
const defaultSizes = {
  sm: 960,
  md: 1400,
  lg: 1800,
  xl: 2028,
  xxl: 2704
};

const ImageUrl = ({
  src,
  alt = '',
  figcaption = null,
  className = '',
  wrapperClassname = '',
  sizes = {}
}) => {
  sizes = Object.assign(defaultSizes, sizes);
  const [imgLoaded, setImgLoaded] = useState(false);
  const imgRef = useRef(null);

  // Use the useEffect to check if the image was loaded
  // *too quickly* to trigger the onLoad event
  useEffect(() => {
    if (imgRef.current?.complete) {
      setImgLoaded(true);
    }
  }, []);
  return (
    <figure className={wrapperClassname}>
      <picture>
        <source
          srcSet={`${src}?w=${sizes.sm}&fm=jpg&q=70`}
          media="(max-width: 480px)"
        />
        <source
          srcSet={`${src}?w=${sizes.md}&fm=jpg&q=70`}
          media="(max-width: 768px)"
        />
        <source
          srcSet={`${src}?w=${sizes.lg}&fm=jpg&q=70`}
          media="(max-width: 1024px)"
        />
        <source
          srcSet={`${src}?w=${sizes.xl}&fm=jpg&q=70`}
          media="(max-width: 1440px)"
        />
        <img
          ref={imgRef}
          className={classnames(`ImageUrl ${className}`, {
            ImageUrl__loaded: imgLoaded
          })}
          src={`${src}?w=${sizes.xxl}&fm=jpg&q=70`}
          alt={alt}
          onLoad={() => {
            setImgLoaded(true);
          }}
        />
      </picture>
      {figcaption && <figcaption>{figcaption}</figcaption>}
    </figure>
  );
};

export default ImageUrl;
