import React, { useState } from 'react';
import classnames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import Link from 'components/base/Link';
import ImageUrl from 'components/base/ImageUrl';
import BlockLinksList from 'components/modules/BlockLinksList';

const CollectionCatalog = ({
  taxa,
  items,
  name = '',
  prevUrl = '',
  className = ''
}) => {
  const [itemsIndex, setItemsIndex] = useState(20);
  const itemsIndexIncrementer = 20;
  return (
    <article
      className={`${className} container container--thin container--boxed mt4`}
    >
      {name.length > 0 && (
        <section className="block flex relative justify-center align-center pv3 body-copy-only">
          <Link
            to={prevUrl.length ? `/collection/${prevUrl}` : `/collection`}
            className="absolute CollectionTaxon__back-link ph3 ph4-xl db gradient-hover bn"
          >
            &lt; Previous
          </Link>
          <h3 className="body-subheader ph3 ph4-xl ttu tc">
            {name} ({items.length} items)
          </h3>
        </section>
      )}
      {taxa.length > 0 ? (
        <section
          className={classnames('block body-copy', {
            bt: name.length > 0
          })}
        >
          <ul className="block flex flex-wrap">
            {taxa.map((item, index) => {
              return (
                <li
                  key={index}
                  className={classnames('w-100 w-third-md body-copy brn', {
                    'br-md': index % 3 !== 2,
                    bb: index < taxa.length - 1,
                    'bbn-md':
                      index === taxa.length - 1 ||
                      (index === taxa.length - 2 && index % 3 !== 2) ||
                      (index === taxa.length - 3 &&
                        index % 3 !== 2 &&
                        index % 3 !== 1)
                  })}
                >
                  <Link
                    to={`/collection/${item.slug}`}
                    className="ttu ph3 ph4-xl pv2 db gradient-hover bn"
                  >
                    {item.name} ({item.itemsLen})
                  </Link>
                </li>
              );
            })}
          </ul>
        </section>
      ) : null}
      {items.length !== 0 ? (
        <>
          <section className="block bt body-copy">
            <InfiniteScroll
              className="block flex flex-wrap"
              element="ul"
              hasMore={items.length >= itemsIndex}
              loadMore={() => {
                setTimeout(() => {
                  setItemsIndex(itemsIndex + itemsIndexIncrementer);
                }, 30);
              }}
            >
              {items.slice(0, itemsIndex).map((item, index) => {
                return (
                  <li
                    key={index}
                    className={classnames('w-100 body-copy', {
                      'w-50-md brn': item.displayImage.length,
                      'br-md': item.displayImage.length && index % 2 === 0,
                      bb:
                        (!item.displayImage.length &&
                          index < items.slice(0, itemsIndex).length - 1) ||
                        (item.displayImage.length &&
                          index < items.slice(0, itemsIndex).length - 2),
                      'bbn-md':
                        (item.displayImage.length &&
                          items[index + 1] &&
                          !items[index + 1].displayImage.length) ||
                        (index % 2 === 0 &&
                          item.displayImage.length &&
                          items[index + 1] &&
                          items[index + 1].displayImage.length &&
                          ((items[index + 2] &&
                            !items[index + 2].displayImage.length) ||
                            !items[index + 2])),
                      bt:
                        !item.displayImage.length &&
                        index !== 0 &&
                        items[index - 1].displayImage.length
                    })}
                  >
                    <Link
                      to={`/collection/${item.slug}`}
                      className="ph3 ph4-xl pv2 db gradient-hover bn"
                    >
                      {item.displayImage.length > 0 && (
                        <ImageUrl
                          wrapperClassname="mt3"
                          className="CollectionCatalog__image"
                          src={item.displayImage}
                          alt={item.name}
                          sizes={{
                            sm: 752,
                            md: 752,
                            lg: 752,
                            xl: 920,
                            xxl: 1252
                          }}
                        />
                      )}
                      <p>{item.name}</p>
                    </Link>
                  </li>
                );
              })}
            </InfiniteScroll>
          </section>
        </>
      ) : null}
      <BlockLinksList className="bt" isNavList={true} />
    </article>
  );
};

export default CollectionCatalog;
